.thumbnail-preview-wrapper {
    width: 100%;
    // overflow: hidden;
    max-height: 185px;
    min-height: 185px;
    height: 185px;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.thumbnail-preview-wrapper-small {
    width: 100%;
    // overflow: hidden;
    max-height: 150px;
    min-height: 150px;
    height: 150px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


.thumbnail-preview-wrapper-large {
    width: 100%;
    // overflow: hidden;
    max-height: 250px;
    min-height: 250px;
    height: 250px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.dropzone-wrapper {
    border-radius: 10px !important;
    border: none !important;
    box-shadow: 0 0 8px #ddd;
    min-height: 180px !important;
    color: #444 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        font-size: 18px !important;
    }
    svg {
        color: #444 !important;
    }
}

.dropzone-wrapper-small {
    border-radius: 10px !important;
    border: none !important;
    box-shadow: 0 0 8px #ddd;
    min-height: 120px !important;
    color: #444 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        font-size: 16px !important;
    }
    svg {
        color: #444 !important;
        font-size: 16px !important;
        width: 40px;
    }
}

.gallery-grid {
    .img-checked::after {
        content: "✔";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        height: 150px;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 40px;
        line-height: 150px;
        text-align: center;
        border: 2px solid white;
        // border-radius: 50%;
        background-color: rgba($color: #000000, $alpha: 0.5);
    }
}

.gallery-tile {
    .MuiGridListTile-tile {
        // overflow: hidden;
        border-radius: 4px;
        box-shadow: 0 0 2px #ddd;
        background: white;
        cursor: pointer;
        &:hover {
            border-radius: 4px;
            box-shadow: 0 0 24px #ddd;
            z-index: 1000;
        }
    }
}
.error-wrapper {
    $anims: float, floatReverse, float2, floatReverse2;
    $easeSmooth: cubic-bezier(0.39, 0.575, 0.28, 0.995);
    $color: #595959;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: $white;
    .particle {
        position: absolute;
        display: block;
        pointer-events: none;

        @for $i from 1 through 80 {
            &:nth-child(#{$i}) {
                $size: random(20) + 10;
                $blur: $i * 0.02;
                $speed: random(20) + 20;
                $delay: random(10) * 0.1;
                $anim: nth($anims, random(length($anims)));

                top: random(100) / (100 + $size/8) * 100%;
                left: random(100) / (100 + $size/10) * 100%;
                font-size: $size + px;
                filter: blur((#{$blur}) + px);
                animation: $speed + s $anim infinite;
            }
        }
    }

    @keyframes apparition {
        from {
            opacity: 0;
            transform: translateY(100px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes float {
        0%,
        100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(180px);
        }
    }

    @keyframes floatReverse {
        0%,
        100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-180px);
        }
    }

    @keyframes float2 {
        0%,
        100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(28px);
        }
    }

    @keyframes floatReverse2 {
        0%,
        100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-28px);
        }
    }

    @mixin animation($delay, $duration, $animation) {
        animation-delay: $delay;
        animation-duration: $duration;
        animation-name: $animation;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
    }
    .img-wrapper {
        height: 50vh;
        width: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .txt-wrapper {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        align-items: center;
        box-shadow: 0 0 10px #eee;
        background-color: $white;
        padding: 2rem 1rem;
        z-index: 999;
        // animation: apparition 0.8s 1.2s $easeSmooth forwards;
        .status-code {
            font-weight: 700;
            font-family: itc-avant-garde-gothic-pro, sans-serif;
            font-size: 5.2rem;
            line-height: 57px;
        }
        .subtext {
            font-size: 3.2rem;
            line-height: 46px;
            font-family: ff-tisa-web-pro, serif;
            font-weight: 200;
        }
        .description {
            font-size: 1.2rem;
            line-height: 26px;
            color: #4a4a4a;
            font-family: proxima-nova, arial, sans-serif;
            font-weight: 200;
            width: 75%;
            text-align: center;
        }
        button {
            width: 200px;
            padding: 0.5rem;
        }
    }
}

.header-menu-list-item {
    box-shadow: 0 0 3px rgba($color: #000000, $alpha: .2) !important;
    cursor: pointer;
    &:hover{
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: .15) !important;
    }
}

.delete{
    width: 26px;
    float: right;
    background: #ee3124;
    text-align: center;
    border-radius: 50%;
    color: white;
    margin: 6px auto;
}