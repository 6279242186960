

.modelForOrder .modal-dialog-centered {
    max-width: 1100px !important;
}

.orderdetailModelheader {
    padding: 1rem !important
}

.orderdetailModelBody {
    padding: 2rem !important;
}
.productNameStyle {
    margin-right: 5px !important;
  }
  .badgeClassPrimary {
      background-color: #0d6efd;
      padding: 8px !important;
  }