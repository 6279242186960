@import "~bootstrap/scss/bootstrap";

// p,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// .h1,
// .h2,
// .h3,
// .h4,
// .h5,
// .h6 {
//   margin-bottom: 0 !important;
// }

@import "assets/scss/mixin";
@import "assets/scss/common";
@import "assets/scss/footer";
@import "assets/scss/rooms";
@import "assets/scss/_flatPikr";
@import "assets/scss/_modalDialog";

.accordian-flex-column {
  flex-direction: column;
}

.accordian-class-custom {
  flex-basis: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.action-icon-edit,
.action-icon-clone {
  cursor: pointer;
  color: #215c4f;
}
.data-table-extensions {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  @media (max-width: 575px) {
    display: block;
  }
}

.data-table-extensions > .data-table-extensions-filter {
  float: unset;
  margin-right: 0.4rem;
  border: 1px solid #f5f5f5;
  padding: 0.4rem;
  border-radius: 5px;
}

.data-table-extensions > .data-table-extensions-action {
  z-index: 1;
  float: unset;
}

.data-table-extensions > .data-table-extensions-filter > .filter-text {
  border-bottom: 0;
}

.data-table-extensions > .data-table-extensions-filter > .icon {
  float: right;
  background-position: right center;
  margin-bottom: 0;
  height: 30px;
}

.action-icon-delete {
  cursor: pointer;
  color: #fc3b3b;
  margin-left: 1rem;
}

.card-header {
  background: transparent !important;
  border-bottom: 0 !important;
  .card-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
  }
}
.card-body {
  padding: 0 1rem;
}
